import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import 'moment-timezone';
import { LocalStorageKeys } from '../constants';

@Pipe({
  name: 'DisplayTime',
  pure: false
})
export class DisplayTimePipe implements PipeTransform {

  transform(value: string, type: string = null, duration: number = 0): string {
    try {
      if (value) {
        let convertType = JSON.parse(localStorage.getItem(LocalStorageKeys.PREFERRED_TIMEZONE));
        if (convertType == null || convertType == undefined) {
          convertType = 'CET';
        }

        let result;
        switch (convertType) {
          case 'UTC': // Display time in UTC
            result = value;
            break;
          case 'CET': // Display time in CET/ CEST
            result = moment(value).tz("Europe/Berlin");
            break;
        }

        if (type) { // Display time in specific format
          if (duration == 0)
            result = moment(result).format(type);
          else
            result = moment(result).subtract(duration, 'seconds').format(type);
        } else if (convertType == 'CET') { // Display time in Norwegian format | DD-MM-YYYYTHH:mm:ss
          result = moment(result).format('DD-MM-YYYYTHH:mm:ss');
        }
        if (result != "Invalid date") {
          return result;
        }
      }
      return value;
    } catch (error) {
      return value;
    }
  }
}